<template>
	<div>
		<div class="row">
			<div class="col-lg-12">
				<div class="panel">
				    <div class="panel-body">
				        <h3 class="panel-title"><img :src="require('@/assets/images/icons/card.svg')" class="panel-icon" alt=""> Cài đặt nạp thẻ cào</h3>
				        <div class="alert alert-info">
				        	Truy cập : <a href="https://thesieure.com/merchant/list" class="au" target="_blank">https://thesieure.com/merchant/list</a> ( Để lấy thông tin tích hợp API ) <br>
				        	Đi tới phần thêm mới : <br>
				        	- Tên: Điền gì cũng được <br>
				        	- Kiểu: GET <br>
				        	- Đường dẫn nhận dữ liệu (Callback Url): <a href="" class="au">{{ site_name }}</a> ( Nhập link web hiện tại ) <br>
				        	- Địa chỉ IP (không bắt buộc): Có thể bỏ qua phần này <br>
				        	Sau đó bấm <a href="" class="au">Thêm thông tin kết nối</a> rồi Copy thông tin API gồm <a href="" class="au">Partner ID</a> + <a href="" class="au">Partner Key</a> quay lại Form cài đặt nạp thẻ cào này rồi thêm là xong!
				        </div>
				        <form action="#" @submit.prevent="submit">
				        	<div class="form-group" v-if="config.status != 2">
				        		<label>Nguồn :</label>
				        		<select class="form-control" v-model="config.provider">
				        			<option :value="source" v-for="(name, source) in config.source">{{ name }}</option>
				        		</select>
				        	</div>
				        	<div class="form-group" v-if="config.status != 2">
				        		<label>Partner ID :</label>
				        		<input type="text" class="form-control" placeholder="Nhập Partner ID từ thesieure.com" v-model="config.partner_id">
				        	</div>
				        	<div class="form-group" v-if="config.status != 2">
				        		<label>Partner Key :</label>
				        		<input type="text" class="form-control" placeholder="Nhập Partner Key từ thesieure.com" v-model="config.partner_key">
				        	</div>
				        	<div class="form-group">
				        		<label>Trạng thái :</label>
				        		<select class="form-control" v-model="config.status">
				        			<option :value="k" v-for="(lists, k) in config.statusLists">{{ lists }}</option>
				        		</select>
				        	</div>
				        	<div class="alert alert-info" v-if="config.status === 2">Nạp trực tiếp qua {{ $store.state.owner_data.site_name.toUpperCase() }} tiền sẽ cộng về tài khoản tại {{ $store.state.owner_data.site_name.toUpperCase() }} của bạn để khách tiếp tục thực hiện mua dịch vụ.</div>
				        	<button class="btn b-success">Cập nhật</button>
				        </form>
				    </div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="panel">
				    <div class="panel-body">
				        <h3 class="panel-title"><img :src="require('@/assets/images/icons/lists.svg')" class="panel-icon" alt=""> Danh sách thẻ cào <button class="btn b-info btn-sm" @click="listsCard"><i class="fa fa-refresh"></i></button></h3>

				        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <select class="form-control" v-model="search.limit" @change="listsCard">
                                        <option :value="rows" v-for="rows in $store.state.limitRowsTable">{{ rows | tableRows }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <input type="text" class="form-control" v-model="search.text" placeholder="Tìm kiếm...">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <select class="form-control" v-model="search.status" @change="listsCard">
                                    	<option value="wait">Đợi duyệt</option>
                                    	<option value="success">Thành công</option>
                                    	<option value="fail">Thất bại</option>
                                    	<option value="all">Tất cả</option>
                                    </select>
                                </div>
                            </div>
                        </div>

				        <div class="table-responsive">
				        	<table class="table table-bordered table-hover">
				        		<thead>
				        			<tr>
				        				<th>#</th>
				        				<th>Username</th>
				        				<th>Trạng thái</th>
				        				<th>Nhà mạng</th>
				        				<th>Mệnh giá</th>
				        				<th>Thực nhận</th>
				        				<th>Mã Serial</th>
				        				<th>Mã Thẻ</th>
				        				<th>Thời gian</th>
				        			</tr>
				        		</thead>
				        		<tbody>
				        			<tr v-for="(card, k) in filter">
				        				<td>{{ card_lists.length - k }}</td>
				        				<td v-html="card.username"></td>
				        				<td v-html="card.status"></td>
				        				<td v-html="card.type"></td>
				        				<td v-html="card.price"></td>
				        				<td v-html="card.price_discount"></td>
				        				<td v-html="card.serial"></td>
				        				<td v-html="card.pincode"></td>
				        				<td>
				        					{{ card.action_time | timeText }}
				        				</td>
				        			</tr>
				        		</tbody>
				        	</table>
				        </div>
				    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped=""></style>

<script>
	export default {
		data () {
			return {
				card_lists: [],
				config: {},
				site_name: window.location.protocol + '//' + window.location.hostname + '/',
				search: {
					limit: 50,
					text: '',
					status: 'all'
				}
			}
		},
		created () {
			this.getConfig();
			this.listsCard();
		},
		computed: {
			filter () {
				if (this.search.text) {
					return this.card_lists.filter(x => {
						return (
							x.username.toLowerCase().includes(this.search.text.toLowerCase()) ||
							x.pincode.toLowerCase().includes(this.search.text.toLowerCase()) ||
							x.serial.toLowerCase().includes(this.search.text.toLowerCase()) ||
							x.price.replace(',', '').toLowerCase().includes(this.search.text.toLowerCase()) ||
							x.type.toLowerCase().includes(this.search.text.toLowerCase()) ||
							x.status.toLowerCase().includes(this.search.text.toLowerCase())
						);
					});
				}
				return this.card_lists;
			}
		},
		methods: {
			getConfig () {
				this.$http.post('admin/settings?action=card', {config: 1}).then(res => {
					if (res.body) {
						this.config = res.body;
					}
				});
			},
			listsCard () {
				this.$http.post('card?action=lists', this.search).then(res => {
					if (res.body) {
						this.card_lists = res.body;
					}
				});
			},
			submit () {
				this.config.action = 'card';
				this.$http.post('admin/settings', this.config).then(res => {
					if (res.body.status == 1) {
						this.$store.dispatch('siteData');
					}
					this.$swal('Thông báo', res.body.message, (res.body.status == 1 ? 'success' : 'error'));
				});
			}
		}
	}
</script>